import classNames from 'classnames'

type Props = {
  isOpen: boolean
  message: string
  variant?: 'success' | 'warning' | 'clipboard'
}

export const Alert = ({ isOpen, message, variant = 'warning' }: Props) => {
  const classes = classNames('toast', {
    'toast--warning': variant === 'warning',
    'toast--success': variant === 'success',
    'toast--clipboard': variant === 'clipboard',
  })

  if (!isOpen) {
    return null
  }

  return <div className={classes}>{message}</div>
}
