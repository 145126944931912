import { Cell } from '../grid/Cell'
import { BaseRow } from '../grid/BaseRow'
import { BaseModal } from './BaseModal'
import { MAX_CHALLENGES } from '../../constants/settings'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="How to play" isOpen={isOpen} handleClose={handleClose}>
      <p>
        Guess the wrestling word in {MAX_CHALLENGES} attempts. After each guess, the colour of the tiles will change to
        show how close your guess was to the word.
      </p>
      <p>A new word is released every 12 hours.</p>

      <BaseRow>
        <Cell value="S" status="correct" />
        <Cell value="U" />
        <Cell value="P" />
        <Cell value="L" />
        <Cell value="E" />
        <Cell value="X" />
      </BaseRow>
      <p>The letter S is in the word and in the correct spot.</p>

      <BaseRow>
        <Cell value="R" />
        <Cell value="U" />
        <Cell value="M" status="present" />
        <Cell value="B" />
        <Cell value="L" />
        <Cell value="E" />
      </BaseRow>
      <p>The letter M is in the word but in the wrong spot.</p>

      <BaseRow>
        <Cell value="L" />
        <Cell value="E" />
        <Cell value="S" />
        <Cell value="N" status="absent" />
        <Cell value="A" />
        <Cell value="R" />
      </BaseRow>
      <p>The letter N is not in the word in any spot.</p>
    </BaseModal>
  )
}
