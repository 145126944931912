export const WORDS = [
  'rousey',
  'driver',
  'brisco',
  'splash',
  'muraco',
  'walter',
  'hitman',
  'kamala',
  'nelson',
  'belair',
  'winner',
  'riddle',
  'garcia',
  'saxton',
  'xavier',
  'koloff',
  'hotrod',
  'moxley',
  'slater',
  'funker',
  'howley',
  'coffey',
  'sasaki',
  'pearce',
  'spears',
  'stoker',
  'patera',
  'bivens',
  'silver',
  'turner',
  'nattie',
  'family',
  'kozlov',
  'parker',
  'knight',
  'gordon',
  'kidman',
  'breeze',
  'future',
  'frazer',
  'wizard',
  'hudson',
  'killer',
  'graham',
  'bearer',
  'iceman',
  'strong',
  'angels',
  'hammer',
  'gigolo',
  'wanted',
  'draper',
  'festus',
  'sherri',
  'brooks',
  'harris',
  'wilson',
  'priest',
  'teoman',
  'rellik',
  'kimura',
  'dundee',
  'morgan',
  'bowens',
  'finkel',
  'penzer',
  'bronco',
  'steelz',
  'tamina',
  'cowboy',
  'clutch',
  'gujjar',
  'caster',
  'mccool',
  'rogers',
  'rawley',
  'conrad',
  'tensai',
  'skyler',
  'castle',
  'wilder',
  'shanky',
  'wright',
  'dorado',
  'reggie',
  'madusa',
  'hassan',
  'dallas',
  'graves',
  'knobbs',
  'conway',
  'severn',
  'hansen',
  'takagi',
  'powers',
  'diablo',
  'miller',
  'demott',
  'maclin',
  'cabana',
  'duggan',
  'briggs',
  'janela',
  'huxley',
  'madman',
  'albert',
  'suplex',
  'phenom',
  'rhodes',
  'makabe',
  'hayter',
  'cesaro',
  'mcafee',
  'tanaka',
  'miguel',
  'armbar',
  'viktor',
  'impact',
  'rosser',
  'dawson',
  'benoit',
  'animal',
  'starks',
  'vacant',
  'vachon',
  'tozawa',
  'garvin',
  'taurus',
  'ryback',
  'dudley',
  'archer',
  'harper',
  'lariat',
  'konnan',
  'norton',
  'dillon',
  'funaki',
  'bourne',
  'carter',
  'abbott',
  'brooke',
  'morton',
  'hennig',
  'maniac',
  'suzuki',
  'legacy',
  'colons',
  'finlay',
  'maryse',
  'shomiz',
  'dragon',
  'basham',
  'morley',
  'theory',
  'shirai',
  'jordan',
  'romero',
  'ibushi',
  'taylor',
  'velvet',
  'mizdow',
  'deaner',
  'mahaan',
  'lorcan',
  'cutter',
  'senshi',
  'monkey',
  'eagles',
  'austin',
  'wolves',
  'sakura',
  'nakano',
  'lesnar',
  'sanada',
  'sawyer',
  'kronik',
  'prince',
  'lauren',
  'eugene',
  'virgil',
  'tenzan',
  'savage',
  'reject',
  'cutler',
  'bellas',
  'reigns',
  'heenan',
  'ciampa',
  'albano',
  'shield',
  'shotzi',
  'moolah',
  'thorne',
  'magnus',
  'putski',
  'aliyah',
  'kharma',
  'fulton',
  'rivera',
  'torres',
  'devlin',
  'madcap',
  'legend',
  'apollo',
  'adonis',
  'colter',
  'sensei',
  'grimes',
  'tajiri',
  'blayze',
  'otunga',
  'hirsch',
  'sabian',
  'wagner',
  'lawlor',
  'abadon',
  'policy',
  'bodies',
  'shango',
  'konnor',
  'nunzio',
  'corbin',
  'kojima',
  'maivia',
  'buster',
  'bayley',
  'dangan',
  'kanyon',
  'sarray',
  'styles',
  'erichs',
  'cheese',
  'sunset',
  'lawler',
  'hendry',
  'steele',
  'zelina',
  'damian',
  'luther',
  'domino',
  'avalon',
  'brazil',
  'melina',
  'devitt',
  'bailey',
  'mendes',
  'gibson',
  'wonder',
  'senton',
  'george',
  'lethal',
  'ripley',
  'corino',
  'london',
  'heyman',
  'marvez',
  'rapada',
  'outlaw',
  'murphy',
  'dupree',
  'taichi',
  'diesel',
  'ashley',
]
