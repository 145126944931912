import { useState, useEffect } from 'react'
import Countdown from 'react-countdown'
import { StatBar } from '../stats/StatBar'
import { Histogram } from '../stats/Histogram'
import { GameStats } from '../../lib/localStorage'
import { shareStatus } from '../../lib/share'
import { tomorrow } from '../../lib/words'
import { BaseModal } from './BaseModal'
import {
  STATISTICS_TITLE,
  GUESS_DISTRIBUTION_TEXT,
  NEW_WORD_TEXT,
  SHARE_TEXT,
  INSTALL_PWA_TEXT,
} from '../../constants/strings'

const IosPWAPrompt = ({ isOpen }: { isOpen: boolean }) => {
  if (!isOpen) {
    return null
  }

  return (
    <div className="pwa-ios-prompt">
      Click{' '}
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 34.4" height="1.5em">
        <path d="M18.3 8.1 13 2.8 7.7 8.1 6.3 6.7 13 0l6.7 6.7-1.4 1.4z" />
        <path d="M12 1.4h2v21h-2v-21z" />
        <path d="M23 34.4H3c-1.7 0-3-1.3-3-3v-18c0-1.7 1.3-3 3-3h7v2H3c-.6 0-1 .4-1 1v18c0 .6.4 1 1 1h20c.6 0 1-.4 1-1v-18c0-.6-.4-1-1-1h-7v-2h7c1.7 0 3 1.3 3 3v18c0 1.7-1.3 3-3 3z" />
      </svg>{' '}
      and select 'Add to Home Screen'
    </div>
  )
}

type Props = {
  isOpen: boolean
  handleClose: () => void
  guesses: string[]
  gameStats: GameStats
  isGameLost: boolean
  isGameWon: boolean
  handleClipboardAlert: () => void
  handleAddToHomeScreenPrompt: [Event | null, () => void]
}

export const StatsModal = ({
  isOpen,
  handleClose,
  guesses,
  gameStats,
  isGameLost,
  isGameWon,
  handleClipboardAlert,
  handleAddToHomeScreenPrompt,
}: Props) => {
  const [installIsVisible, setInstallIsVisible] = useState(false)
  const [iOSPromptIsVisible, setIosPromptIsVisible] = useState(false)
  const [prompt, promptToInstall] = handleAddToHomeScreenPrompt

  const isIOS = /iPad|iPhone/.test(navigator.userAgent)

  useEffect(() => {
    if (prompt) {
      setInstallIsVisible(true)
    }
  }, [prompt])

  useEffect(() => {
    if (iOSPromptIsVisible) {
      setTimeout(() => setIosPromptIsVisible(false), 10000)
    }
  }, [iOSPromptIsVisible])

  if (gameStats.totalGames <= 0) {
    return (
      <BaseModal title={STATISTICS_TITLE} isOpen={isOpen} handleClose={handleClose}>
        <StatBar gameStats={gameStats} />
      </BaseModal>
    )
  }

  return (
    <BaseModal title={STATISTICS_TITLE} isOpen={isOpen} handleClose={handleClose}>
      <StatBar gameStats={gameStats} />

      <h3>{GUESS_DISTRIBUTION_TEXT}</h3>
      <Histogram gameStats={gameStats} />

      {/* {(isGameLost || isGameWon) && ( */}
      <>
        <h3>{NEW_WORD_TEXT}</h3>

        <Countdown className="countdown" date={tomorrow} daysInHours={true} />

        <button
          type="button"
          className="btn"
          onClick={() => {
            shareStatus(guesses, isGameLost, handleClipboardAlert)
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="20" height="20" fill="currentcolor">
            <path d="M352 320c-25.6 0-48.9 10-66.1 26.4l-98.3-61.5c5.9-18.8 5.9-39.1 0-57.8l98.3-61.5C303.1 182 326.4 192 352 192c53 0 96-43 96-96S405 0 352 0s-96 43-96 96c0 9.8 1.5 19.6 4.4 28.9l-98.3 61.5C144.9 170 121.6 160 96 160c-53 0-96 43-96 96s43 96 96 96c25.6 0 48.9-10 66.1-26.4l98.3 61.5c-2.9 9.4-4.4 19.1-4.4 28.9 0 53 43 96 96 96s96-43 96-96-43-96-96-96zm0-272c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zM96 304c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm256 160c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48z" />
          </svg>
          {SHARE_TEXT}
        </button>

        {(installIsVisible || isIOS) && (
          <button
            type="button"
            className="btn  pwa-install-btn"
            onClick={() => (isIOS ? setIosPromptIsVisible(true) : promptToInstall())}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="20" height="20" fill="currentcolor">
              <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm129.9-206.1l-19.6-19.6c-4.8-4.8-12.5-4.7-17.2.2L282 300.8V140c0-6.6-5.4-12-12-12h-28c-6.6 0-12 5.4-12 12v160.8l-67.1-70.3c-4.7-4.9-12.4-5-17.2-.2l-19.6 19.6c-4.7 4.7-4.7 12.3 0 17l121.4 121.4c4.7 4.7 12.3 4.7 17 0l121.4-121.4c4.7-4.7 4.7-12.3 0-17z" />
            </svg>
            {INSTALL_PWA_TEXT}
          </button>
        )}

        <IosPWAPrompt isOpen={iOSPromptIsVisible} />
      </>
      {/* )} */}
    </BaseModal>
  )
}
