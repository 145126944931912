import { GameStats } from '../../lib/localStorage'

type Props = {
  gameStats: GameStats
}

const HistogramItem = ({ index, size, label }: { index: number; size: number; label: string }) => {
  return (
    <div className="histogram__item">
      <div className="histogram__item-label">{index + 1}</div>
      <div className="histogram__item-bar">
        {size > 0 ? <div style={{ width: `${size}%` }}>{label}</div> : <div>{label}</div>}
      </div>
    </div>
  )
}

export const Histogram = ({ gameStats }: Props) => {
  const winDistribution = gameStats.winDistribution
  const maxValue = Math.max(...winDistribution)

  return (
    <div className="histogram">
      {winDistribution.map((value, i) => (
        <HistogramItem key={i} index={i} size={100 * (value / maxValue)} label={String(value)} />
      ))}
    </div>
  )
}
