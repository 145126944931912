import { MAX_WORD_LENGTH } from '../../constants/settings'
import { BaseRow } from './BaseRow'
import { Cell } from './Cell'

export const EmptyRow = () => {
  const emptyCells = Array.from(Array(MAX_WORD_LENGTH))

  return (
    <BaseRow>
      {emptyCells.map((_, i) => (
        <Cell key={i} />
      ))}
    </BaseRow>
  )
}
