import { getGuessStatuses } from './statuses'
import { solutionIndex } from './words'
import { GAME_TITLE } from '../constants/strings'
import { MAX_CHALLENGES } from '../constants/settings'

export const shareStatus = (guesses: string[], lost: boolean, clipboardAlert: Function) => {
  let textToShare = `${GAME_TITLE} - Game ${solutionIndex + 1} - ${
    lost ? 'X' : guesses.length
  }/${MAX_CHALLENGES}\n\n${generateEmojiGrid(guesses)}`

  // Check for Windows, so we don't show the share dialog
  const isWindows = /Windows/.test(navigator.userAgent)

  if (navigator.share && !isWindows) {
    navigator.share({
      text: textToShare,
      url: 'https://itrwrestling.com/word-rumble',
    })
  } else {
    textToShare += '\n\nPlay now 👉 https://itrwrestling.com/word-rumble'

    navigator.clipboard.writeText(textToShare).then(() => {
      clipboardAlert()
    })
  }
}

export const generateEmojiGrid = (guesses: string[]) => {
  return guesses
    .map((guess) => {
      const status = getGuessStatuses(guess)
      return guess
        .split('')
        .map((_, i) => {
          switch (status[i]) {
            case 'correct':
              return '🟩'
            case 'present':
              return '🟨'
            default:
              return '⬜️'
          }
        })
        .join('')
    })
    .join('\n')
}
