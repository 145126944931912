import { CharStatus } from '../../lib/statuses'
import classnames from 'classnames'

type Props = {
  value?: string
  status?: CharStatus
}

export const Cell = ({ value, status }: Props) => {
  const classes = classnames('cell', {
    'cell--no-status': !status,
    'cell--filled': value && !status,
    'cell--absent': status === 'absent',
    'cell--correct': status === 'correct',
    'cell--present': status === 'present',
    'cell-animation': !!value,
  })

  return <div className={classes}>{value}</div>
}
